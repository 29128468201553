<template>
	<article class="course-editor-block-container">
		<h2 class="course-editor-block-header" v-html="doMark(content)"></h2>
		<section class="d-flex flex-row justify-space-between">
			<figure
				v-for="node in nodes"
				class="d-flex flex-column"
				:key="node.id"
			>
				<div class="comparison-block-img-container">
					<img :src="node.file.public_url" alt="" />
				</div>
				<p v-html="doMark(node.content)"></p>
			</figure>
		</section>
	</article>
</template>

<script>
import marked from 'marked'

export default {
	name: "CourseEditorBlockComparison",
	props: {
		content: {
			type: String,
			require: false
		},
		nodes: {
			type: Array,
			require: false
		}
	},
	methods: {
		doMark(e) {
			return marked(e)
		}
	}
};
</script>

<style lang="scss" scoped>
.comparison-block-img-container {
	max-width: 150px;
}
</style>
