<template>
	<figure class="course-editor-block-container">
		<figure
			class="mb-4 course-editor-block-container"
		><h2 class="course-editor-block-header" v-html="doMark(question)"></h2></figure>
		<v-row
			class="widget-connect__row"
			v-for="(question, index) in sourcesNodes"
			:key="question.id"
			:id="question.id"
		>
			<v-col cols="5">
				<article>
					<p v-html="doMark(question.content)"></p>
				</article>
			</v-col>
			<v-col cols="2" class="widget-connect__row--iconwrapper"
				><span class="widget-connect__row--icon"
					><v-icon>mdi-chevron-right-box</v-icon></span
				></v-col
			>
			<v-col cols="5">
				<article>
					<p v-html="doMark(targetNodes[index].content)"></p>
				</article>
			</v-col>
		</v-row>
	</figure>
</template>

<script>
import marked from 'marked'
export default {
	name: "CourseEditorBlockConnect",
	props: {
		question: {
			type: String,
			require: true
		},
		sourcesNodes: {
			type: Array,
			require: true
		},
		targetNodes: {
			type: Array,
			require: true
		}
	},
	computed: {
		tag() {
			return `<h2 class="course-editor-block-header">${this.question}</h2>`;
		},
		itemsContent() {
			return this.items.map(el => JSON.parse(el.content));
		}
	},
	methods: {
		doMark(e) {
			return marked(e)
		}
	}
};
</script>
