<template>
	<figure class="course-editor-block-container">
		<img class="block-image" :src="pathUrl" alt="" />
	</figure>
</template>

<script>
export default {
	name: "CourseEditorBlockImage",
	props: ["file"],
	computed: {
		pathUrl() {
			return this.file?.public_url;
		}
	}
};
</script>

<style lang="scss" scoped>
.block-image {
	max-width: 200px;
}
</style>
