<template>
	<figure class="course-editor-block-container" v-html="tag"></figure>
</template>

<script>
import marked from 'marked'
export default {
	name: "CourseEditorBlockHeader",
	props: {
		header: {
			type: String,
			require: true
		},
		headerLevel: {
			type: Number,
			require: true
		}
	},
	computed: {
		tag() {
			return `<h${this.headerLevel}>${marked(this.header)}</h${this.headerLevel}`;
		}
	}
};
</script>
