<template>
	<figure class="course-editor-block-container">
		<h2 class="course-editor-block-header" v-html="doMark(question)"></h2>
		<ul>
			<li
				v-for="option in options"
				:key="option.id"
				:class="{ 'correct-answer': option.correct === 1 }"
			 v-html="doMark(option.content)"></li>
		</ul>
	</figure>
</template>

<script>
import marked from 'marked'
export default {
	name: "CourseEditorBlockRadio",
	props: {
		options: {
			type: Array,
			require: false
		},
		question: {
			type: String,
			require: false
		}
	},
	methods: {
		doMark(e) {
			return marked(e)
		}
	}
};
</script>

<style lang="scss" scoped>
.correct-answer {
	color: rgb(17, 129, 51);
	font-weight: bold;
}
</style>
